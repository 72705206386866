import React, { useState } from 'react';
import "primereact/resources/themes/mdc-light-deeppurple/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import { Menubar } from 'primereact/menubar';

export default function Footer() {

    return (
                <Menubar />
    )
}